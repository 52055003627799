<template>
  <div id="user-share-data">
    <!-- 头部 -->
    <div class="user-header">
      <img class="share-log" src="../../../../static/img/userData/share_log.svg" alt="" />
      <span @click="shareDetialToUserDetial">{{getString(strings.User_My_Share)}}</span>
      <span @click="myShareMore" style="margin-left: 10px">{{
        shareMoreMenuName
      }}</span>
      <span style="margin-left: 1px">{{ shareMoreMenuChildName }}</span>
      <span v-if="this.$router.history.current.name != 'meMoreMind'">({{ myShareCount }})</span
      >
      <div
        v-if="this.$router.history.current.name != 'meMoreMind'"
        class="user-share-more"
        @click="getUserShareMind"
      >
        <span style="font-size: 14px;color: #666666;">{{getString(strings.User_More_Message)}}</span>
        <img src="../../../../static/img/userData/look_more_mind.svg" alt="" />
      </div>
    </div>

    <!-- 分类 -->
    <div
      v-if="
        this.$router.history.current.name != 'LookMessage' &&
        this.$router.history.current.name != 'Details' &&
        this.$router.history._startLocation != '/Home/LookMessage'
      "
      class="share-mind-header"
    >
      <div
        class="share-mind-and-link"
        v-for="(item, index) in shareTitle"
        :key="index"
        :class="shareTileAccout == index ? 'set-title-style' : ''"
        @click="setTitleStyle(index)"
      >
        {{ getString(item) }}
      </div>
    </div>

    <!-- 内容 -->

    <!-- 主页展示 -->
    <div class="share-content" v-if="routerName == 0 && initUserShareData.items != null && initUserShareData.items.length > 0">
      <div
        class="share-cover"
        v-for="(item, index) in initUserShareData.items"
        :key="index"
        @click="moreShareOrcollectionToLink(item)"
      >
        <div
          class="share-pc-cover"
          :style="'display:none; background-color:' + colorList[index] + ';'"
        >
          <!-- <img
            src="../../../../static/img/userData/link_logo.png"
            v-real-img="item.cover"
            alt=""
          /> -->
        </div>
        <div class="share-text">
          <div class="share-mind-titel">
            <div class="share-mind-titel-text">{{ item.title }}</div>
            <div class="share-mind-delete"></div>
          </div>
          <div
            class="share-look-data"
            @click.stop="moreShareOrcollectionToLink(item)"
          >
            <div class="share-look-time">
              {{ timestampToTimeDHM(item.createTime) }}
            </div>
            <div class="share-look-count">
              <img
                src="../../../../static/img/userData/user_mind_lookcount.svg"
                alt=""
              />
              {{ item.viewCount }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 更多风暴 -->
    <transition name="slide-fade">
      <div class="share-potato-chips" v-if="routerName == 1">
        <div
          class="share-pc-box"
          v-for="(item, index) in initUserShareData.sharedItems"
          :key="index"
        >
          <div
            class="share-pc-cover"
            @click="moreShareOrcollectionToLink(item)"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-pc-text">
            <div
              class="share-pc-title"
              @click="moreShareOrcollectionToLink(item)"
            >
              <span>{{ item.title }}</span>
            </div>
            <div
              class="share-pc-text-content"
              @click="moreShareOrcollectionToLink(item)"
            >
              <span>{{ item.subtitle }}</span>
            </div>
            <div class="share-pc-bottom">
              <div
                class="share-pc-time"
                @click="moreShareOrcollectionToLink(item)"
              >
                <span>{{ timestampToTimeDHM(item.createTime) }}</span>
              </div>
              <div class="share-details">
                <div class="share-details-single">
                  <img
                    src="../../../../static/img/userData/user_mind_lookcount.png"
                    alt=""
                  />
                  <span>{{ item.viewCount }}</span>
                </div>
                <div class="share-details-single">
                  <img
                    src="../../../../static/img/userData/news_logo.png"
                    alt=""
                    @click="postCrispsVoteCrisps(item.itemId)"
                  />
                  <span>{{ item.commentCount }}</span>
                </div>
                <div class="share-details-single">
                  <img
                    src="../../../../static/img/userData/like_log.png"
                    alt=""
                    @click="postCrispsVoteCrisps(item.itemId)"
                  />
                  <span v-if="item.voteCount != 0">{{ item.voteCount }}</span>
                </div>
                <a-popover
                  v-model="item.visible"
                  placement="bottomLeft"
                  trigger="click"
                >
                  <template slot="content">
                    <div
                      class="share-delete-content"
                      @click="deleteCrispsAccout(item.itemId, index)"
                    >
                      <img
                        src="../../../../static/img/userData/delete_logo.png"
                        alt=""
                      />
                      <span>{{getString(strings.User_Deleted_Sotrm)}}</span>
                    </div>
                  </template>
                  <a-button
                    ><img
                      src="../../../../static/img/userData/details_x_logo.png"
                      alt=""
                  /></a-button>
                </a-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 更多链接 -->
    <transition name="slide-fade">
    <div class="share-content share-detail" v-if="routerName == 2">
      <div
        class="share-cover"
        v-for="(item, index) in initUserShareData.sharedItems"
        :key="index"
      >
        <div
          class="share-pc-cover"
          @click="moreShareOrcollectionToLink(item)"
          :style="'background-color:' + colorList[index] + ';'"
        >
          <img src="../../../../static/img/userData/link_logo.png" alt="" />
        </div>
        <div class="share-text">
          <div class="share-mind-titel">
            <div
              class="share-mind-titel-text"
              @click="moreShareOrcollectionToLink(item)"
            >
              {{ item.title }}
            </div>
            <div class="share-mind-delete">
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="collectItemLinkToEdit(item.mindMapId)"
                  >
                    <img
                      style="width: 18px"
                      src="../../../../static/img/userData/edit_link.svg"
                      alt=""
                    />
                    <span>{{getString(strings.Global_Edit)}}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="deleteCollectItem(item.itemId, index)"
                  >
                    <img
                      src="../../../../static/img/userData/delete_logo.png"
                      alt=""
                    />
                    <span>{{getString(strings.Mind_Mindmap_Deleted_Forever)}}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.svg"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
          </div>
          <div
            class="share-look-data"
            @click.stop="moreShareOrcollectionToLink(item)"
          >
            <div class="share-look-time">
              {{ timestampToTimeDHM(item.createTime) }}
            </div>
            <div class="share-look-count">
              <img
                src="../../../../static/img/userData/user_mind_lookcount.svg"
                alt=""
              />
              {{ item.viewCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition >
    
    <!-- 数据为空 -->
    <div
      class="user-share-data-null-page"
      :class="
        this.$router.history.current.name == 'meMoreMind'
          ? 'user-share-data-null'
          : 'user-share-data-null-page'
      "
      v-if="initUserShareNoData"
    >
      <div class="data-null">
        <img
          style="width: 120px;"
          src="../../../../static/img/userData/user_share_null_logo.png"
          alt=""
        />
        <span>{{getString(strings.User_Share_Is_Null)}}</span>
      </div>
    </div>
    <!-- 删除模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsDelete"
      :title="getString(strings.Mind_Mindmap_Deleted_Forever)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @ok="commentCrispsDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{getString(strings.User_Deleted_Storm_Tips)}}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { postUserMySharedItems } from "../../../common/netWork/base_api";

import dataTimeToFormats from "../../../utils/timestampToTime";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import { postMindmapDeleteShare } from "../../../common/netWork/mind_map_api";
import { postCrispsDelete } from "../../../common/netWork/crisps_api";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";

import crisps from "../../../assets/css/crisps/crisps.less";

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  data() {
    return {
      shareTitle: [strings.Storm, strings.User_Message_Link],
      shareTileAccout: 0,
      routerName: true,
      initUserShareData: {},
      myShareCount: 0,
      initUserShareNoData: true,
      shareMoreMenuName: "",
      shareMoreMenuChildName: "",
      nowElectron: false,
      reportCrispsDelete: false,
      shareId: null,
      visible: false,
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ],
      strings:strings
    };
  },
  props: {
    initUserData: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  created() {},
  mounted() {
    this.nowElectron = this.$tools.isElectron();
    var router = this.$router.history.current.name;
    if (router == "meMoreMind") {
      this.routerName = 1;
      this.shareMoreMenuName = "> " + getString(strings.User_More_Message);
      this.postUserMySharedItems();
    } else {
      this.routerName = 0;
    }
  },
  methods: {
    //语言包
    getString(i){
      return getString(i)
    },
    openMind(item) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item);
    },
    //我的分享
    getUserShareData() {
      if (this.initUserShareData.items == null) {
        return;
      }
      let itemsLength = this.initUserShareData.items.length;
      let items = this.initUserShareData.items;
      for (let i = 0; i < itemsLength; i++) {
        var cover = items[i].cover;
        this.initUserShareData.items[i].cover = httpImageToPrefix(cover);
      }
      this.myShareCount = this.initUserShareData.items.length;
      if (this.myShareCount > 0) {
        this.initUserShareNoData = false;
      } else {
        this.initUserShareNoData = true;
      }
      this.addBackgroundColor(this.initUserShareData.items);
    },
    //更多数据
    getMoreUserShareData() {
      // console.log("更多数据");
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.timestampToTimeDHM(timestamp);
    },
    //添加背景颜色
    addBackgroundColor(array) {
      if (array.length > this.colorList.length) {
        for (let index = 0; index < array.length; index++) {
          if (array.length != this.colorList.length) {
            this.colorList.push(this.colorList[index]);
          }
        }
      }
    },
    //风暴or链接数据
    //数据
    postUserMySharedItems() {
      var type;
      if (this.shareTileAccout == 0) {
        type = 2;
      } else if (this.shareTileAccout == 1) {
        type = 1;
      }
      postUserMySharedItems({ type: type, lastItemId: "" }, (res) => {
        this.initUserShareData = res;
        let sharedItemsLength = this.initUserShareData.sharedItems.length;
        let sharedItems = this.initUserShareData.sharedItems;
        for (let i = 0; i < sharedItemsLength; i++) {
          var cover = sharedItems[i].cover;
          this.initUserShareData.sharedItems[i].cover = httpImageToPrefix(
            cover
          );
        }
        this.myShareCount = this.initUserShareData.sharedItems.length;
        if (this.myShareCount > 0) {
          this.initUserShareNoData = false;
        } else {
          this.initUserShareNoData = true;
        }
        // console.log(res.sharedItems);
        this.addBackgroundColor(res.sharedItems);
      });
    },
    //风暴or链接
    setTitleStyle(index) {
      this.shareTileAccout = index;
      if (index == 0) {
        this.postUserMySharedItems();
        this.routerName = 1;
      } else if (index == 1) {
        this.postUserMySharedItems();
        this.routerName = 2;
      }
    },
    //更多导图
    getUserShareMind() {
      // console.log(this.initUserShareData);
      this.$router.push({
        path: "/Home/meMoreMind",
        query: { id: this.initUserShareData.userId },
      });
    },
    //风暴点赞
    postCrispsVoteCrisps(id) {
      if (this.nowElectron) {
        this.openMind("/c/" + id);
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/c/" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    //删除风暴
    deleteCrispsAccout(id, index) {
      this.shareId = id;
      this.reportCrispsDelete = true;
      this.initUserShareData.sharedItems[index].visible = false;
    },
    //删除分享链接
    deleteCollectItem(id, index) {
      this.shareId = id;
      this.reportCrispsDelete = true;
      this.initUserShareData.sharedItems[index].visible = false;
    },
    //确定删除
    commentCrispsDeleteHandleOk() {
      this.reportCrispsDelete = false;
      if (this.shareTileAccout == 0) {
        postCrispsDelete(
          { crispsId: this.shareId },
          (res) => {
            this.$message.success(getString(strings.Message_Tips_Dlt_Success));
            this.postUserMySharedItems();
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.shareTileAccout == 1) {
        postMindmapDeleteShare({ mindMapId: this.shareId }, (res) => {
          this.$message.success(getString(strings.Message_Tips_Dlt_Success));
          this.postUserMySharedItems();
        });
      }
    },
    //跳转个人详情页
    shareDetialToUserDetial() {
      this.$router.push("/Home/Details");
    },
    //跳转更多
    myShareMore() {},
    //跳转编辑页面
    collectItemLinkToEdit(id) {
      if (this.nowElectron) {
        this.openMind("/mindmap?id=" + id);
      } else {
        let newWindow = window.open();
        const routerData = this.$router.resolve({
          path: "/mindmap?id=" + id,
        });
        newWindow.location = routerData.href;
      }
    },
    //跳转 链接1 风暴2
    moreShareOrcollectionToLink(item) {
      if (this.nowElectron) {
        if (item.type == 1) {
          this.openMind("/s/" + item.itemId);
        } else if (item.type == 2) {
          this.openMind("/c/" + item.itemId);
        }
      } else {
        if (item.type == 1) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/s/" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 2) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/c/" + item.itemId,
          });
          newWindow.location = routerData.href;
        }
      }
    },
  },
  watch: {
    initUserData(newInitUserData) {
      this.initUserShareData = newInitUserData;
      this.getUserShareData();
    },
    $route(to, from) {
      var router = to.path;
      if (router == "meMoreMind") {
        this.routerName = false;
        this.postUserMySharedItems();
      } else {
        this.routerName = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
#user-share-data {
    background: #fff;
    padding: 20px;
  .user-header {
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    .share-log{
      margin: 20px 10px 20px 10px;
    }
    img {
      margin: 20px 10px 20px 20px;
    }
    span {
      font-size: 18px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      color: #333;
      opacity: 1;
    }
    .user-share-more {
      position: absolute;
      right: 27px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      span {
        transition: 0.3s;
      }
    }
    .user-share-more:hover {
      // span {
      //   color: #fd492b;
      //   transition: 0.3s;
      // }
      // img {
      //   filter: drop-shadow(#fd492b -100px 0);
      //   transform: translateX(-100px);
      // }
    }
  }

  .share-mind-header {
    display: flex;
    border-bottom: 3px solid #f0f2f8;
    height: 40px;
    .share-mind-and-link {
      width: 86px;
      font-size: 16px;
      height: 38px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      margin: 0 30px;
    }
    .set-title-style {
      border-bottom: 3px solid #fd492b;
      color: #fd492b;
      width: 86px;
    }
  }

  .share-content {
    width: 1590px;
    min-height: 78px;
    margin-top: -12px;
    display: flex;
    height: 78px;
    overflow: hidden;
    .share-cover {
      box-shadow: 0px 3px 6px rgba(80, 80, 80, 0.16);
      margin: 10px 10px;
      width: 180px;
      height: 62px;
      border-radius: 0px 22px 0px 0px;
      background: rgba(0, 0, 0, 0);
      border: solid 1px #bebebe;
      cursor: pointer;
      .share-pc-cover {
        width: 180px;
        height: 160px;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        display: none;
        justify-content: center;
        align-items: center;
        img {
          transition: 0.5s;
        }
        img:hover {
          transform: scale(1.1);
          transition: 0.5s;
        }
      }
      cursor: pointer;
      .share-text {
        width: 180px;
        height: 62px;
        border-radius: 4px 4px 0px 0px;
        .share-mind-titel {
          margin: 5px 0px 10px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .share-mind-titel-text {
            overflow: hidden;
            width: 168px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            color: #666666;
            font-size: 14px;
          }
          .share-mind-delete {
            width: 20px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
          }
          button {
            border: none;
            height: 20px;
            padding: 0px 10px;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
        .share-look-data {
          margin: 3px 10px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          .share-look-time{
            color: #999999;
            font-size: 12px;
          }
          .share-look-count{
            display: flex;
            align-items: center;
            img{
              margin-right: 6px;
            }
          }
        }
        .share-mind-titel-look {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }
    .share-cover:hover {
      box-shadow: 5px 8px 13px rgba(80, 80, 80, 0.16);
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.5s;
      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
  .share-detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: justify;
  }

  .share-potato-chips {
    width: 100%;
    margin: 20px 20px;
    .share-pc-box {
      display: flex;
      margin-top: 20px;
      .share-pc-cover {
        width: 140px;
        height: 126px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 6px #bebebe;
        opacity: 1;
        border-radius: 10px;
        cursor: pointer;
      }
      .share-pc-cover img {
        transition: 0.5s;
      }
      .share-pc-cover img:hover {
        transform: scale(1.1);
        transition: 0.5s;
      }
      .share-pc-text {
        width: 733px;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .share-pc-title {
          width: 100%;
          height: 25px;
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 25px;
          color: #333333;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
        }
        .share-pc-text-content {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .share-pc-bottom {
          width: 500px;
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
          opacity: 1;
          display: flex;
          position: relative;
          align-items: center;
          .share-pc-time {
            position: absolute;
            left: 0;
            cursor: pointer;
          }
          .share-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            width: 288px;
            .share-details-single {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 10px;
              }
            }
            button {
              border: none;
              background-color: transparent;
            }
          }
        }
      }
    }
  }

  //空数据
  .user-share-data-null-page {
    display: flex;
    justify-content: center;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      img {
        margin-bottom: 30px;
      }
    }
  }
  .user-share-data-null {
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      img {
        margin-bottom: 30px;
      }
    }
  }
}

// ant design vue
.share-delete-button {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 18px 23px;
}
.share-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}
</style>